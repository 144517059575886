const theme = {
  color: {
    textBlack: "rgba(0, 0, 0, 0.7)",
    textDarkGray: "rgba(0, 0, 0, 0.6)",
    textGray: "rgba(0, 0, 0, 0.3)",
    textBlue: "#6c95bc",
    textRed: "#eb7878"
  },
  unit: {
    getRem(px) {
      return `${px / 16}rem`;
    }
  }
};

export default theme;
