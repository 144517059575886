import React from "react";
import { Router } from "@reach/router";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Appointment from "@contact-smarter/book-appointment";

import ErrorBoundary from "./ErrorBoundary";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  body {
    background-image: linear-gradient(129deg, #7fb2dc, #949ae2);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0 8px;
`;

const StyledNotFound = styled.div`
  color: #fff;
  text-align: center;
  font-weight: 400;

  h1 {
    font-size: ${props => props.theme.unit.getRem(72)};
    margin: 16px 0;
  }
`;

const NotFound = () => (
  <React.Fragment>
    <StyledNotFound>
      <h1>404</h1>
      <span>Oops! sorry page does not found.</span>
    </StyledNotFound>
  </React.Fragment>
);

const Booking = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyle />
          <Wrapper>
            <Router>
              <Appointment
                path="c/:campaign"
                environment={process.env.REACT_APP_ENVIRONMENT}
              />
              <Appointment
                path="p/:prospect"
                environment={process.env.REACT_APP_ENVIRONMENT}
              />
              <NotFound default />
            </Router>
          </Wrapper>
        </React.Fragment>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default Booking;
